import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import SupportModalMobile from "./SupportModalMobile";
import Logo from "../assets/images/logo.svg";
import cross from "../assets/images/cross.svg";
import burger from "../assets/images/burger.svg";
import googleLogo from "../assets/images/googleLogoWhite.svg";
import appleLogo from "../assets/images/appleLogo.svg";

const LogoBlock = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LogoImage = styled.img`
  width: 39px;
  height: 48px;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #282b34;
  padding: 20px;
  border-radius: 20px;
  width: 412px;
  height: 244px;
  max-width: 400px;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
`;

const HeaderWrapper = styled.header`
  padding: 20px 0 20px 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1136px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const LogoText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 10px;
  font-family: Inter;
  @media (max-width: 768px) {
    display: none; /* Hide text on mobile */
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-right: 20px;

  a {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    text-decoration: none;
    font-size: 18px;
    font-family: Inter;
    @media (max-width: 768px) {
      display: none; /* Hide on mobile */
    }
    &:hover {
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
  }
`;

const Button = styled.button`
  background-color: #f1cc06;
  width: 242px;
  height: 52px;
  border: none;
  color: #14161b;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  font-family: Inter;

  &:hover {
    background-color: #ffe03a;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonSignIn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  background-color: rgba(255, 255, 255, 0.1);
  height: 52px;
  border: none;
  color: rgba(255, 255, 255, 1);
  padding: 12px 18px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  font-family: Inter;

  // &:hover {
  //   background-color: #ffe03a;
  // }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-family: Inter;
    background-color: #f1cc06;
    color: #14161b;
    width: 172px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    padding: 0;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ffe03a;
    }
  }
`;

const ButtonMobileSignIn = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    font-family: Inter;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    width: 172px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    padding: 0 18px 0 18px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ffe03a;
    }
  }
`;

const BurgerMenuButton = styled.div`
  cursor: pointer;
  display: none; /* По умолчанию скрыт */

  @media (max-width: 768px) {
    display: flex;
    padding: 0 20px 0 0px;
  }
`;

const BurgerIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const MobileMenuBackdrop = styled.div`
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -60;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const MobileMenuContent = styled(ModalContent)`
  width: 100%;
  height: 100%;
  padding: 100px 0 0 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileMenuLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0; /* Spacing between items */
  font-family: Inter;
  line-height: 20px;
`;

const Header = ({ openModal }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileMenuOpenSupport, setIsMobileMenuOpenSupport] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Получаем данные из localStorage
    const storedProgress = localStorage.getItem("progress");
    if (storedProgress) {
      const parsedProgress = JSON.parse(storedProgress);
      setUser(parsedProgress.user);
    }
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSignInClick = () => {
    navigate("/signin");
  };

  return (
    <HeaderWrapper>
      <>
        <LogoBlock style={{ display: "flex", alignItems: "center" }}>
          <LogoImage src={Logo} alt="MiroLang Logo" />
          <LogoText>MiroLang</LogoText>
        </LogoBlock>

        <NavLinks>
          {/* <a href="#about">О проекте</a> */}
          {/* <a href="#pro">О MiroLang Pro</a> */}
          <a href="#contact" onClick={openModal}>
            Обратная связь
          </a>
        </NavLinks>

        {user ? (
          <ButtonSignIn onClick={handleSignInClick}>
            <img
              src={user.provider === "google" ? googleLogo : appleLogo}
              alt="Logo"
              width="24"
            />
            {user.email}
          </ButtonSignIn>
        ) : (
          <Button onClick={handleSignInClick}>Войти в аккаунт</Button>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {user ? (
            <ButtonMobileSignIn onClick={handleSignInClick}>
              <img
                src={user.provider === "google" ? googleLogo : appleLogo}
                alt="Logo"
                width="24"
              />
              {user.email}
            </ButtonMobileSignIn>
          ) : (
            <ButtonMobile onClick={handleSignInClick}>
              Войти в аккаунт
            </ButtonMobile>
          )}

          <BurgerMenuButton onClick={toggleMobileMenu}>
            <BurgerIcon src={burger} alt="Меню" />
          </BurgerMenuButton>
        </div>
      </>

      {isMobileMenuOpen && (
        <MobileMenuBackdrop>
          <MobileMenuContent>
            <CloseButton onClick={toggleMobileMenu} src={cross} alt="close" />

            <MobileMenuLink
              href="#contact"
              onClick={() => {
                toggleMobileMenu();
                setIsMobileMenuOpenSupport(true);
              }}
            >
              Обратная связь
            </MobileMenuLink>
          </MobileMenuContent>
        </MobileMenuBackdrop>
      )}

      {isMobileMenuOpenSupport && (
        <SupportModalMobile
          closeModal={() => setIsMobileMenuOpenSupport(false)}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
